import AbstractApiModule from '../../AbstractApiModule';

const urlFactory = {
  getDataCalculatorSSG   : '/v1/des/test/calculator/data/',
  setResultCalculatorSSG : '/v1/des/test/calculator/result/',
  getProductsCalculator  : '/v2/internal/specialist/calculator/products/',

  getResultCalculatorLenses: (type) => `/v1/calculator/${type}/calculate/`
};

export default class CalculatorModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  getDataCalculatorSSG() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getDataCalculatorSSG).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  setResultCalculatorSSG(params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.setResultCalculatorSSG, params).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getProductsCalculator() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getProductsCalculator).then(({ data }) => {
        resolve(data);
      }).catch(reject);
    });
  }

  getResultCalculatorLenses(type, params) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.getResultCalculatorLenses(type), params).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }
}
