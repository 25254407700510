import AbstractApiModule from '../../AbstractApiModule';
import { getConvertDataBasket } from '../../../utils/basket';

const urlFactory = {
  setPromoCode     : (type) => `/v1/${type}/basket/promo_code/apply/`,
  unsetPromoCode   : (type) => `/v1/${type}/basket/promo_code/remove/`,
  getListPromoCode : '/v1/promo-code/certs/',
  getUserPoints    : '/v1/profile/score/',
  buyPromoCode     : '/v1/promo-code/personal/by-cert/',

  getListActiveCerts : '/v2/internal/personal/promocodes/',
  getListUsedCerts   : '/v1/promo-code/personal/certs/history/'
};

const configFormData = {
  headers: { 'Content-Type': 'multipart/form-data' }
};

export default class PromoCodeModule extends AbstractApiModule {
  constructor(options) {
    super(options);
    this.apiURL = options.apiURL;
  }

  setPromoCode(type, params = {}) {
    const form = new FormData();
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
      this.post(urlFactory.setPromoCode(type), form, configFormData).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve(result);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  unsetPromoCode(type, params = {}) {
    const form = new FormData();
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });

    return new Promise((resolve, reject) => {
      this.post(urlFactory.unsetPromoCode(type), form, configFormData).then(({ data }) => {
        if (data && data.data) {
          const result = getConvertDataBasket(data.data);
          resolve(result);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getListPromoCode() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListPromoCode).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getUserPoints() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getUserPoints).then(({ data }) => {
        if (data && 'data' in data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getListActiveCerts() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListActiveCerts).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  getListUsedCerts() {
    return new Promise((resolve, reject) => {
      this.get(urlFactory.getListUsedCerts).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }

  buyPromoCode(id) {
    return new Promise((resolve, reject) => {
      this.post(urlFactory.buyPromoCode, { id }).then(({ data }) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          reject(data);
        }
      }).catch(reject);
    });
  }
}
